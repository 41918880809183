.pl{
     padding: 100px 100px;
     display: flex;
     flex-direction: column;
     align-items: center;
     text-align: center;
}
.pl-texts{
    width: 65%;
    
}
.pl-title{
    font-style: 50px;
    font-weight: 600;
    font-size: 40px;
    color: #0077BE; 
}
.pl-desc{
    margin: 20px opx;
    font-size: 20px;
}
.pl-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width:480px) {
    .pl{
        padding: 10px;

    }
    .pl-texts{
        width: 100%;
        
    }
    .pl-desc{
        display: none;
    }
    .pl-title{
        padding: 10px;
    }
}

@media screen and (max-width:480px) {
    .pl{
        padding: 10px;
    }
    .pl-texts{
        width: 100%;
    }
    .pl-desc{
        display: none;
    }
}
