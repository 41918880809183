.p{
    width: 30%;
    height: 40vh;
    margin: 20px 10px;
    border: 2px solid rgb(243, 242, 242);
    border-radius:10px 10px 0px 0px ;
    overflow: hidden;
}

.p-browser{
    height: 20px;
    display: flex;
    align-items: center;
    background-color: rgb(243, 242, 242);
    position: sticky;
    z-index: 2 ;
}
.p-circle{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: #d73c3c;
}
.p-img{
    width: 100%;
    transition: all 5s ease;
}
.p:hover .p-img {
    transform: translateY(-100%);
}


@media screen and (max-width: 480px) {
    .p{
        width: 40%;
        height: 20vh;
        margin: 20px 0px;
        border-radius:10px;
    }
    
}
