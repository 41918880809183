.c {
    height: 160vh;
    position: relative;
}

.c-bg {
    width: 20px;
    height: 100%;
    position: absolute;
}

.c-wrapper {
    padding: 50px;
    display: flex;
}

.c-left {
    flex: 1;
}

.c-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.c-title {
    font-size: 60px;
    width: 8%; /* This width is very small, consider using percentage or pixel value */
}

.c-info-item {
    display: flex;
    align-items: center;
    margin: 50px 0; /* You may adjust this margin */
    font-weight: 300;
    width: 70%;
}

.c-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.c-desc {
    font-weight: 400; /* This should be "font-weight: 400;" not "font-weight: 400px;" */
    font-size: 50px;
}

form {
    margin-top: 20px;
}

input {
    width: 50%;
    height: 50%;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px opx; /* Typo, should be "10px 0px" */
    font-size: 14px;
    padding-left: 10px;
}

textarea {
    width: 100%;
    margin: 10px opx; /* Typo, should be "10px 0px" */
    font-size: 14px;
    padding-left: 10px;
}

button {
    border: none;
    padding: 15px;
    font-size: 16px;
    background-color: #0077B6;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #005C8D;
}

@media screen and (max-width: 480px) {
    .c-wrapper {
        flex-direction: column;
        padding: 0px 50px; /* Consider using "padding: 0" for mobile devices to remove padding */
    }

    .c-left {
        order: 2;
    }

    .c-right {
        order: 1;
    }

    .c-title {
        font-size: 40px;
        text-align: center;
        width: 100%;
    }

    .c-info-item {
        flex-direction: column;
        margin: 30px 0;
        width: 100%;
    }

    .c-icon {
        margin-right: 0;
    }

    .c-desc {
        font-size: 30px;
        text-align: center;
    }

    form {
        margin-top: 30px;
    }

    input {
        width: 100%;
        height: 40px;
        margin: 10px 0;
    }

    textarea {
        height: 100px;
        margin: 10px 0;
    }

    button {
        width: 100%;
        padding: 10px;
        font-size: 16px;
    }
}
